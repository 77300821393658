import React, { useRef, useState } from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import FormInput from "../duplicateComponents/FormInput";
import FormInputTextArea from "../duplicateComponents/FormInputTextArea";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";
import { NotificationsToast } from "../duplicateComponents/NotificationsToast";

const SectionContactUs = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_4wm4j8h",
        "template_unn2fei",
        form.current,
        "qvXEW1USUZMOwQxs4"
      )
      .then(
        (result) => {
          setLoading(false);
          NotificationsToast("done", "تم إرسال طلب ");
          // console.log(result.text);
        },
        (error) => {
          setLoading(false);
          NotificationsToast("error", "هناك مشكلة في إرسال الطلب ");
        }
      );
  };
  return (
    <Fade bottom>
      <div className="container p-3 p-md-5 mt-0 mt-5" id="contact-us-homepage">
        <TitleHeadSpecialHomePage title={"تواصـل معنـا"} />
        {/* <div className="text-center fw-bold mb-3">
        في حال كان لديك أي استفسارات او أسئلة تواصل معنا عبرالنموذج التالي
      </div> */}
        {/* col one */}
        <div
          className="row mt-0 style-shadow-small  mx-1"
          style={{ borderRadius: "var(--radius)", minHeight: "50vh" }}
        >
          <form
            ref={form}
            onSubmit={sendEmail}
            className="col-12 col-lg-6 border-radius-contact-us-1 bg-white d-flex justify-content-center align-items-center flex-column p-3"
          >
            <div className="col-12 col-sm-10 my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                name={"from_name"}
                label={"الأسم"}
                placeholder={"الأسم"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12 col-sm-10 my-1">
              <FormInput
                type={"text"}
                onChange={() => null}
                name={"from_email"}
                label={"البريد الإلكتروني"}
                placeholder={"البريد الإلكتروني"}
                idAndfor={"validationCustomIdNo"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="col-12 col-sm-10 my-1">
              <FormInputTextArea
                // onChange={handelChangeForms}
                name={"from_msg"}
                label={"رسالتك"}
                placeholder={"رسالتك"}
                idAndfor={"validationCustomIdPrivate"}
                isValid={"valid"}
                msgValid={"Gooooooooood !!"}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              {loading ? (
                <button
                  className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
                  id="style-btn-start-service"
                  style={{
                    width: "200px",
                  }}
                >
                 ... جاري الارسال
                </button>
              ) : (
                <button
                  className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
                  id="style-btn-start-service"
                  style={{
                    width: "200px",
                  }}
                >
                  إرسال
                </button>
              )}
            </div>
          </form>
          <div
            className="col-12 col-lg-6 position-relative border-radius-contact-us-2 order-first order-lg-last d-flex justify-content-center align-items-center flex-column p-3 pt-5"
            style={{
              backgroundImage:
                "linear-gradient( 135deg, var(--primary-color) 10%, var(--primary-color) 100%)",
            }}
          >
            <div
              className="position-absolute border-radius-contact-us-2"
              style={{
                backgroundImage: "url('../../images/bg-contact-us.jpg')",
                // backgroundImage: "url('../../images/bg-landing-blur.png')",
                // backgroundRepeat: "no-repeat",
                // minHeight: "60vh",
                // backgroundSize: "100% 100%",
                // filter: "blur(5px)",
                width: "100%",
                height: "100%",
                bottom: "0",
                // opacity: "0.2",
              }}
            ></div>

            <div
              className="position-absolute bg-dar border-radius-contact-us-2"
              style={{
                opacity: "0.7",
                backgroundImage:
                  "linear-gradient( 135deg, var(--primary-color) 10%, var(--primary-color) 100%)",
                width: "100%",
                height: "100%",
                bottom: "0",
              }}
            ></div>
            {/* <div className=""> */}
            {/* <lottie-player
                src="https://lottie.host/0752d72f-92f6-418c-92e4-392e5fb2d4d9/8LhTye0OI2.json"
                background="transparent"
                speed="0.5"
                // style="width: 300px; height: 300px;"
                loop
                autoplay
              ></lottie-player> */}
            {/* </div> */}
            <div
              className="text-center text-white mb-5"
              style={{ zIndex: "100" }}
            >
              <FontAwesomeIcon icon={faHeadset} size="4x" />

              <div className="fs-3 fw-bold mt-4">
                {/* <span className="ms-3">
                  <FontAwesomeIcon icon={faHeadset} size="3x"/>
                </span> */}
                <span>تواصـل معنـا</span>
              </div>
              <p className="mt-3 text-title-icons-blobs">
                في حال كان لديك أي استفسارات او أسئلة تواصل معنا عبرالنموذج
                التالي
              </p>
            </div>
          </div>

          {/* <div className="col-12 col-lg-6">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={"الأسم"}
            placeholder={"الأسم"}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12 col-lg-6">
          <FormInput
            type={"text"}
            onChange={() => null}
            // value={""}
            label={"البريد الإلكتروني"}
            placeholder={"البريد الإلكتروني"}
            idAndfor={"validationCustomIdNo"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="col-12">
          <FormInputTextArea
            // onChange={handelChangeForms}
            // value={""}
            label={"رسالتك"}
            placeholder={"رسالتك"}
            idAndfor={"validationCustomIdPrivate"}
            isValid={"valid"}
            msgValid={"Gooooooooood !!"}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn fw-bold rounded-5 text-center px-3 py-2 mx-0 mx-lg-3"
            id="style-btn-start-service"
            style={{
              width: "200px",
            }}
          >
            إرسال
          </button>
        </div> */}
        </div>
      </div>
    </Fade>
  );
};

export default SectionContactUs;
