/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import FormInput from "../../duplicateComponents/FormInput";
// import AlertMsg from "../../duplicateComponents/AlertMsg";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubmissitionDec,
  addSubmissitionFirstApp,
  getSubmissitionCompanyByCompanyName,
  getSubmissitionCompanyByCompanyName012,
  getSubmissitionCompanyByCompanyName012Page,
  getSubmissitionCompanyByCompanyNamePage,
} from "../../../redux/actions/submissition/ActionSubmissitions";
import { Spin, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ButtonRoundedFill from "../../duplicateComponents/ButtonRoundedFill";
import TableLodaing from "../../duplicateComponents/TableLodaing";
import { role } from "../../../redux/types/Types";
import { NotificationsToast } from "../../duplicateComponents/NotificationsToast";
import TitleWithNumber from "../../duplicateComponents/TitleWithNumber";
const { Column } = Table;

const CompanyOrderApprovedOrDeclined = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(
      getSubmissitionCompanyByCompanyName012(
        JSON.parse(localStorage.getItem("storageDataObj")).company_name
      )
    );
  }, []);
  // api submisstion
  const myDataSubmissitionCompany = useSelector(
    (state) => state.rootSubmissitions.submissitionCompany012
  );

  // api submisstion
  // const responseSubmissitionCompany = useSelector(
  //   (state) => state.rootSubmissitions.submissitionCompany
  // );

  const loadingSubmissitionCompany = useSelector(
    (state) => state.rootSubmissitions.loadingSubmissitionCompany012
  );

  console.log(myDataSubmissitionCompany && myDataSubmissitionCompany.data);
  const [page, setPage] = useState(2);

  const [searchText, setSearchText] = useState("");

  // let lengthSub0and1and2 = [];
  // if (myDataSubmissitionCompany) {
  //   // console.log(myDataSubmissitionCompany);
  //   if (myDataSubmissitionCompany === "s") {
  //     console.log("");
  //   } else {
  //     lengthSub0and1and2 =
  //       myDataSubmissitionCompany &&
  //       myDataSubmissitionCompany.data.filter((item) =>
  //         item.approved < "3" ? item.approved : null
  //       );
  //   }
  // }

  const [isLoad, setIsLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const responseSubmissitionCompany = useSelector(
  //   (state) => state.rootSubmissitions.submissition
  // );

  const responseSubmissitionDecCompany = useSelector(
    (state) => state.rootSubmissitions.submissitionDec
  );
  const responseSubmissitionAppCompany = useSelector(
    (state) => state.rootSubmissitions.submissitionApp
  );

  const submitSubDec = async (e, subId) => {
    e.preventDefault();
    // console.log(subId);
    setIsLoad(true);
    setIsLoading(true);
    await myDispatch(
      addSubmissitionDec({
        "sub-id": subId,
        type: role,
      })
    );
    setIsLoad(false);
  };

  useEffect(() => {
    if (isLoad === false) {
      if (responseSubmissitionDecCompany) {
        // console.log(responseSubmissitionDecCompany);
        myDispatch(
          getSubmissitionCompanyByCompanyName012(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        // thie is for status orders
        myDispatch(
          getSubmissitionCompanyByCompanyName(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        myDispatch(
          getSubmissitionCompanyByCompanyNamePage(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        // myDispatch(getSubmissitionByApproved("2"));
      }

      if (responseSubmissitionDecCompany.status === 200) {
        if (responseSubmissitionDecCompany.data === "somthing wrong") {
          NotificationsToast("warn", null, "تم الرفض مسبقاً");
          setIsLoading(false);
          return;
        }

        if (responseSubmissitionDecCompany.data === "done") {
          NotificationsToast("done", null, "تم رفض المتدرب");
          setIsLoading(false);
          return;
        }
      } else {
        if (responseSubmissitionDecCompany.status !== 200) {
          NotificationsToast("error", "في القبول او الرفض ", " هناك مشكلة");
          setIsLoading(false);
          return;
        }
      }

      setIsLoad(true);
    }
  }, [isLoad]);

  const [isLoadA, setIsLoadA] = useState(true);
  const [isLoadingA, setIsLoadingA] = useState(false);

  const submitSubAppro = async (e, subId) => {
    e.preventDefault();
    setIsLoadA(true);
    setIsLoadingA(true);
    await myDispatch(
      addSubmissitionFirstApp({
        "sub-id": subId,
        type: role,
      })
    );
    setIsLoadA(false);
  };

  useEffect(() => {
    if (isLoadA === false) {
      if (responseSubmissitionAppCompany) {
        // console.log(responseSubmissitionAppCompany);
        myDispatch(
          getSubmissitionCompanyByCompanyName012(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        // thie is for status orders
        myDispatch(
          getSubmissitionCompanyByCompanyName(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        myDispatch(
          getSubmissitionCompanyByCompanyNamePage(
            JSON.parse(localStorage.getItem("storageDataObj")).company_name
          )
        );
        // myDispatch(getSubmissitionByApproved("2"));
      }
      if (responseSubmissitionAppCompany.status !== 200) {
        NotificationsToast("error", "في القبول او الرفض ", " هناك مشكلة");
        setIsLoadingA(false);
        return;
      }
      if (responseSubmissitionAppCompany.status === 200) {
        if (responseSubmissitionAppCompany.data === "somthing wrong") {
          NotificationsToast("warn", null, "تم القبول مسبقاً");
          setIsLoadingA(false);
          return;
        } else if (responseSubmissitionAppCompany.data === "done") {
          NotificationsToast("done", null, "تم قبول المتدرب");
          setIsLoadingA(false);
          return;
        }
      }
    }
  }, [isLoadA]);

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "الاسم",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "الرقم التدريبي",
      dataIndex: "student_id",
      key: "student_id",
      filteredValue: null,
      onFilter: null,
      render: null,
    },
    {
      title: "الكلية",
      dataIndex: "collage_name",
      key: "collage_name",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.collage_name === null ? "-" : record.collage_name;
      },
    },
    {
      title: "القسم",
      dataIndex: "Department",
      key: "Department",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.Department === null ? "-" : record.Department;
      },
    },
    {
      title: "التخصص",
      dataIndex: "major",
      key: "major",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return record.major === null ? "-" : record.major;
      },
    },
    {
      title: "الاجراءات",
      dataIndex: "",
      key: "action",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <>
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<CloseOutlined />}
                  bgColor="var(--red)"
                  color="var(--red)"
                  colorHover="#fff"
                  bgHover="var(--red)"
                  width={40}
                  onclick={(e) => {
                    submitSubDec(e, record.submissions_id);
                    myDispatch(
                      getSubmissitionCompanyByCompanyName012(
                        JSON.parse(localStorage.getItem("storageDataObj"))
                          .company_name
                      )
                    );
                  }}
                />
              </span>{" "}
              <span className="mx-1">
                <ButtonRoundedFill
                  title={<CheckOutlined />}
                  colorHover="#fff"
                  width={40}
                  onclick={(e) => submitSubAppro(e, record.submissions_id)}
                />
              </span>
            </>
          </div>
        );
      },
    },
  ];

  return (
    <Spin
      spinning={isLoading}
      // spinning={false}
      delay={500}
      size="large"
      className="text-danger "
    >
      <div className="placeholder-glow">
        <div className="row">
          <div className="d-flex justify-content-between flex-column flex-sm-row align-items-start align-items-sm-center">
            <span>
              <TitleWithNumber
                title={"جميع الطلبات"}
                subTitle={"عدد الطلبات"}
                // num={
                //   myDataSubmissitionCompany
                //     ? myDataSubmissitionCompany === "s"
                //       ? 0
                //       : myDataSubmissitionCompany.Submissions.length
                //     : "f"
                // }
                num={
                  loadingSubmissitionCompany
                    ? 0
                    : myDataSubmissitionCompany &&
                      myDataSubmissitionCompany.data.total
                }
              />
            </span>
          </div>
          <div className="col-12 col-lg-6 my-3">
            <FormInput
              type={"search"}
              placeholder={"البحث بالأسم - الرقم التدريبي ..."}
              shadow={"style-shadow-large-extra"}
              isShowIcon={true}
              onSearch={(val) => {
                setSearchText(val);
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              bgColor="#fff"
            />
          </div>
          <div>
            {loadingSubmissitionCompany ? (
              <TableLodaing itemsColumns={dataTableColumns} />
            ) : (
              <Table
                pagination={{
                  position: ["bottomCenter"],
                  size: "small",
                  style: { display: "flex", flexDirection: "row-reverse" },
                  // 1,2,3,4
                  current: page,
                  // items show
                  total: loadingSubmissitionCompany
                    ? 0
                    : myDataSubmissitionCompany &&
                      myDataSubmissitionCompany.data.total,
                  pageSize: 10,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    myDispatch(
                      getSubmissitionCompanyByCompanyName012Page(
                        JSON.parse(localStorage.getItem("storageDataObj"))
                          .company_name,
                        page
                      )
                    );
                  },
                }}
                size="middle"
                dataSource={
                  myDataSubmissitionCompany &&
                  myDataSubmissitionCompany.data.data
                }
                rowKey={"student_id"}
                className="style-scroll-radius style-shadow-large bg-white rounded-3"
              >
                {dataTableColumns.map((item, i) => (
                  <Column
                    title={item.title}
                    dataIndex={item.dataIndex}
                    key={item.key}
                    filteredValue={item.filteredValue}
                    onFilter={item.onFilter}
                    render={item.render}
                  />
                ))}
              </Table>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CompanyOrderApprovedOrDeclined;
