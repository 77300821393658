import React from "react";
import { Menu, Sidebar } from "react-pro-sidebar";
import SidebarLogo from "../sideBar/SidebarLogo";
import SideBarItem from "../sideBar/SideBarItem";
import DateAndDay from "../duplicateComponents/DateAndDay";
const HomeSidebar = ({ isPositionFixed = true, refScroll }) => {
  const headerItem = [
    {
      title: "عن مارس",
      path: refScroll[0],
    },
    {
      title: "الخدمات",
      path: refScroll[1],
    },
    {
      title: "مايميزنا",
      path: refScroll[2],
    },
    {
      title: "المستفيدين",
      path: refScroll[3],
    },
    {
      title: "تواصل معنا",
      path: refScroll[4],
    },
  ];
  return (
    <Sidebar
      rtl={true}
      className="border-0"
      rootStyles={{
        position: isPositionFixed ? "fixed" : "relative",
        height: "100%",
        overflowY: "auto !important",
        Zndex: 3,
        top: 0,
        userSelect: "none !important",
      }}
    >
      <div className=" h-100 d-flex justify-content-between flex-column ">
        <Menu>
          <SidebarLogo />
          {headerItem.map((item, i) => (
            <SideBarItem title={item.title} icon={" "} onClick={item.path} />
          ))}
        </Menu>
        <div className="mt-5">
          <div className="my-3">
            <DateAndDay />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default HomeSidebar;
