/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotificationsToast } from "../../components/duplicateComponents/NotificationsToast";
import { authLogin } from "../../redux/actions/auth/ActionAuth";
import { arabicRegex } from "../../redux/types/Types";
import { useAuth } from "./Auth";

const LoginLogic = () => {
  const myDispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShowMsgErrorUsrename, setIsShowMsgErrorUsrename] = useState(false);
  const [isShowMsgErrorPassword, setIsShowMsgErrorPassword] = useState(false);
  const [isShowMsgErrorPasswordLength, setIsShowMsgErrorPasswordLength] =
    useState(false);

  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  // selector
  const responseAuthLogin = useSelector((state) => state.rootAuth.login);
  //   const loadingAuthLogin = useSelector((state) => state.rootAuth.loading);

  const localStorageSaveData = (key, val) => {
    localStorage.setItem(key, val);
  };

  const auth = useAuth();
  const nav = useNavigate();
  const handelLogin = (roleName, path) => {
    localStorageSaveData("role", roleName);
    auth.login(localStorage.getItem("role"));
    window.location.pathname = path;
  };

  const handelUsername = (e) => {
    // if (e.target.value) {
      let split = e.target.value.split("");
      if (split.length < 10) {
        if (split.length > 0) {
          setIsShowMsgErrorUsrename(false);
        } else {
          setIsShowMsgErrorUsrename(true);
        }
        setUsername(split.join(""));
      }
    // }
  };
  const handelPassword = (e) => {
    if (arabicRegex.test(e.target.value)) {
      return;
    }

    if (e.target.value.length > 0) {
      setIsShowMsgErrorPassword(false);
    } else {
      setIsShowMsgErrorPassword(true);
    }
    if (e.target.value.length >= 8) {
      setIsShowMsgErrorPasswordLength(false);
    } else {
      setIsShowMsgErrorPasswordLength(true);
    }
    setPassword(e.target.value);
  };

  //validation username and password

  const submitLogin = async (e) => {
    e.preventDefault();

    if (username === "" && password === "") {
      setIsShowMsgErrorUsrename(true);
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }
    if (username === "") {
      setIsShowMsgErrorUsrename(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }

    if (password === "") {
      setIsShowMsgErrorPassword(true);
      NotificationsToast("warn", " جميع الحقول مطلوبة");
      return;
    }
    if (password.length < 8) {
      setIsShowMsgErrorPasswordLength(true);
      return;
    }
    setLoad(true);
    // this is show btn load if click btn wating give me data from API
    setLoading(true);
    await myDispatch(
      authLogin({
        username: username,
        password: password,
      })
    );
    setLoad(false);
  };

  useEffect(() => {
    if (load === false) {
      if (responseAuthLogin) {
        // console.log(responseAuthLogin);
      }
      if (responseAuthLogin.status !== 200) {
        NotificationsToast("error", " هناك مشكلة في عملية الدخول ");
        // console.log("first");
        setLoading(false);
        return;
      }
      if (responseAuthLogin.status === 200) {
        if (responseAuthLogin.data === "nothing here") {
          localStorage.clear();
          NotificationsToast("warn", " أسم المستخدم أو كلمة المرور خاطئة");
          setLoading(false);
        } else if (responseAuthLogin.data.token) {
          // save token
          localStorage.setItem("token", responseAuthLogin.data.token);
          // save Student Data
          localStorage.setItem(
            "storageDataObj",
            JSON.stringify(responseAuthLogin.data.info)
          );
          localStorage.setItem("role", responseAuthLogin.data.role);
          // Go Routes1
          // i used window.location becouse refresh all pages

          NotificationsToast("done", " تم  الدخول بنجاح");
          if (responseAuthLogin.data.role === "student") {
            setTimeout(() => {
              handelLogin("student", "/student/status");
              setLoading(false);
            }, 800);
          } else if (responseAuthLogin.data.role === "emp") {
            setTimeout(() => {
              handelLogin("emp", "/sopervisor/status");
              setLoading(false);
            }, 800);
          } else if (responseAuthLogin.data.role === "company") {
            setTimeout(() => {
              handelLogin("company", "/company/status");
              setLoading(false);
            }, 800);
          } else if (responseAuthLogin.data.role === "admin") {
            setTimeout(() => {
              handelLogin("admin", "/admin/status");
              setLoading(false);
            }, 800);
          }
        } else {
          // console.log("error === 200");
          localStorage.removeItem("token");
          localStorage.removeItem("storageDataObj");
          NotificationsToast("error", " هناك مشكلة في عملية الدخول ");
          setLoading(false);
        }
      } else {
        // console.log("error !== 200");
        localStorage.removeItem("token");
        localStorage.removeItem("storageDataObj");
        NotificationsToast("error", " هناك مشكلة في عملية الدخول ");
        setLoading(false);
      }
    }
  }, [load]);

  return [
    username,
    password,
    handelUsername,
    handelPassword,
    submitLogin,
    loading,
    isShowMsgErrorUsrename,
    isShowMsgErrorPassword,
    isShowMsgErrorPasswordLength,
  ];
};
export default LoginLogic;
