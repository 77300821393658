import React, { useState } from "react";
import { Select, Spin, Table, Typography } from "antd";
import { useDispatch } from "react-redux";
import Taps from "../../../duplicateComponents/Taps";
import FormInputTextArea from "../../../duplicateComponents/FormInputTextArea";
import { NotificationsToast } from "../../../duplicateComponents/NotificationsToast";
import ButtonLoading from "../../../duplicateComponents/ButtonLoading";
import Button from "../../../duplicateComponents/Button";
import FormInput from "../../../duplicateComponents/FormInput";
import TableLodaing from "../../../duplicateComponents/TableLodaing";
import { getAllTasksPage } from "../../../../redux/actions/tasks/ActionTasks";
import CompanyAdditionTasksLogic from "../../../../logic/company/addition/tasks/CompanyAdditionTasksLogic";
import TitleWithNumber from "../../../duplicateComponents/TitleWithNumber";

const { Column } = Table;
const { Paragraph } = Typography;
const CompanyAdditionTasksContainer = () => {
  const [
    handelTaskName,
    tasksName,
    setArrChecked,
    arrChecked,
    isLoadingAttach,
    submitAddTasks,
    myDataTasks,
    loadingTasks,
    myDataStudentInsideCompany,
    isShowMsgError,
  ] = CompanyAdditionTasksLogic();
  const myDispatch = useDispatch();

  let filteredOptions = [];

  if (myDataStudentInsideCompany) {
    filteredOptions = myDataStudentInsideCompany.filter(
      (o) => !arrChecked.includes(o)
    );
  }

  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const dataTableColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "count",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return (
          <strong style={{ color: "var(--primary-color)" }}>{index + 1}</strong>
        );
      },
    },
    {
      title: "المهمة",
      dataIndex: "task_details",
      key: "task_details",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.task_details)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: null,
    },
    {
      title: "نسخ المهمة",
      dataIndex: "tesk_id",
      key: "tesk_id",
      filteredValue: null,
      onFilter: null,
      render: (text, record, index) => {
        return <Paragraph copyable={{ text: record.task_details }}></Paragraph>;
      },
    },
  ];

  return (
    <Taps
      title={["إضافة المهم", "المهام السابقة"]}
      charIdAndTarget={["a", "b"]}
      titleItem={[
        <>
          <div
            className="col-12 bg-white p-3 style-shadow-small"
            style={{ borderRadius: "var(--radius)" }}
          >
            <div className="col-12 col-lg-6 m-auto">
              <h5
                className="text-center fw-bold my-2"
                style={{ color: "var(--primary-color)" }}
              >
                إضافة مهام للمتدربين
              </h5>
              <div className="row gx-5 gy-3 ">
                <div className="col-12">
                  <FormInputTextArea
                    type={"text"}
                    onChange={handelTaskName}
                    value={tasksName}
                    label={"عنوان المهمة"}
                    placeholder={"عنوان المهمة..."}
                    msgError={isShowMsgError ? "الحقل مطلوب" : ""}
                  />
                </div>
              </div>
              <div
                className=" py-1 my-3 d-flex style-shadow-smal justify-content-betwee align-items-center"
                style={{
                  borderRadius: "var(--radius)",
                  // width: "190px",
                }}
              >
                <span className="">
                  <input
                    className="form-check-input border shadow-none"
                    type="checkbox"
                    // value={arrChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      let newArrCheckedMap = [];

                      if (myDataStudentInsideCompany) {
                        newArrCheckedMap = Object.values(
                          myDataStudentInsideCompany
                        ).map((item) => item.student_id);
                      }

                      if (checked) {
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "done",
                          null,
                          "تم تحديد جميع المتدربين"
                        );
                      } else {
                        newArrCheckedMap = [];
                        setArrChecked(newArrCheckedMap);
                        NotificationsToast(
                          "warn",
                          null,
                          "تم الغاء جميع المتدربين"
                        );
                      }
                    }}
                  />
                </span>{" "}
                <span className="bg-dange fw-bold mx-3">
                  تحديد جميع المتدربين
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column flex-md-row my-3">
            <Select
              mode="multiple"
              placeholder="البحث بالرقم التدريبي"
              value={arrChecked}
              onChange={setArrChecked}
              style={{
                width: "100%",
              }}
              options={
                filteredOptions
                  ? filteredOptions.map((item, i) => ({
                      value: item.student_id,
                      label: item.name + " - " + item.student_id,
                    }))
                  : []
              }
            />

            <div className=" me-0 my-3 my-md-0 me-md-3 d-flex justify-content-center align-items-center ">
              {isLoadingAttach ? (
                <ButtonLoading
                  title={"جاري الاضافة"}
                  className={"fw-bold"}
                  width={"150px"}
                />
              ) : (
                <Button
                  width={"150px"}
                  onclick={submitAddTasks}
                  title={"إرسال"}
                />
              )}
            </div>
          </div>
        </>,
        <>
          <Spin spinning={false} delay={500} size="large">
            <div className="placeholder-glow">
              <div className="row">
                <span>
                  <TitleWithNumber
                    title={"جميع المهام"}
                    subTitle={"عدد المهام"}
                    num={myDataTasks && myDataTasks.total}
                  />
                </span>
                <div className="col-12 col-lg-6 my-3">
                  <FormInput
                    type={"search"}
                    placeholder={"البحث ..."}
                    shadow={"style-shadow-large-extra"}
                    isShowIcon={true}
                    onSearch={(val) => {
                      setSearchText(val);
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    bgColor="#fff"
                  />
                </div>
                <div>
                  {loadingTasks ? (
                    <TableLodaing itemsColumns={dataTableColumns} />
                  ) : (
                    <Table
                      pagination={{
                        position: ["bottomCenter"],
                        size: "small",
                        style: {
                          display: "flex",
                          flexDirection: "row-reverse",
                        },
                        // 1,2,3,4
                        current: page,
                        // items show
                        total: myDataTasks && myDataTasks.total,
                        // pageSize: 100,
                        onChange: (page, pageSize) => {
                          setPage(page);
                          myDispatch(getAllTasksPage(page));
                        },
                      }}
                      size="middle"
                      dataSource={myDataTasks && myDataTasks.data}
                      rowKey={"task_id"}
                      className="style-scroll-radius style-shadow-large bg-white rounded-3"
                    >
                      {dataTableColumns.map((item, i) => (
                        <Column
                          title={item.title}
                          dataIndex={item.dataIndex}
                          key={item.key}
                          filteredValue={item.filteredValue}
                          onFilter={item.onFilter}
                          render={item.render}
                        />
                      ))}
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </Spin>
        </>,
      ]}
    />
  );
};

export default CompanyAdditionTasksContainer;
