/* eslint-disable no-lone-blocks */
import React from "react";
// import ReactLoading from "react-loading";

const TitleWithNumber = ({ title, subTitle, num }) => {
  return (
    <div className="fw-bold  fs-4">
      {title}{" "}
      <div
        className="text-dark text-opacity-50 mt-1 d-flex align-items-center "
        style={{ fontSize: "15px" }}
      >
        <span className="ms-1"> {subTitle}</span>
        <span
          style={{
            color: "var(--primary-color)",
            fontSize: "17px",
          }}
          className=""
        >
          {num === null ? "" : isNaN(num) ? `(${0})` : `(${num})`}
        </span>
      </div>
    </div>
  );
};

export default TitleWithNumber;
{
  /* <ReactLoading
type={"bubbles"}
color={"var(--primary-color)"}
width={40}
height={40}
/> */
}
