/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import HeaderHomePage from "../../components/homePage/HeaderHomePage";
import About from "../../components/homePage/About";
import HLanding from "../../components/homePage/HLanding";
import { useNavigate } from "react-router-dom";
import SectionBeneficiaries from "../../components/homePage/SectionBeneficiaries";
import SectionCpanel from "../../components/homePage/SectionCpanel";
import SectionAdvantages from "../../components/homePage/SectionAdvantages";
import SectionContactUs from "../../components/homePage/SectionContactUs";
import Footer from "../../components/homePage/Footer";
import { useState } from "react";
const HomePage = () => {
  const nav = useNavigate();

  // const [isHideOrShow, setIsHideOrShow] = useState(false);

  // var lastScrollTop = window.pageYOffset;
  // const hideAndShowHeader = () => {
  //   window.onscroll = () => {
  //     var scrollTop = window.pageYOffset;
  //     if (scrollTop < 100) {
  //       setIsHideOrShow(false);
  //     } else if (scrollTop > lastScrollTop) {
  //       setIsHideOrShow(true);
  //     } else {
  //       setIsHideOrShow(false);
  //     }

  //     lastScrollTop = scrollTop;
  //   };
  // };
  const refHeader = useRef();

  const hideAndShowHeader = () => {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (document.querySelector(".nana") !== null) {
        if (currentScrollPos < 90) {
          document.querySelector(".nana").style.top = "0";
        } else if (prevScrollpos > currentScrollPos) {
          document.querySelector(".nana").style.top = "0";
        } else {
          document.querySelector(".nana").style.top = "-86px";
        }
      }
      prevScrollpos = currentScrollPos;
    };
  };

  useEffect(() => {
    hideAndShowHeader();
    if (localStorage.getItem("token") || localStorage.getItem("role")) {
      if (localStorage.getItem("role") === "student") {
        nav("/student/status");
      } else if (localStorage.getItem("role") === "emp") {
        nav("/sopervisor/status");
      } else if (localStorage.getItem("role") === "") {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/login"
        ) {
        }
      }
    }
  }, [window.location.pathname]);

  const refAbout = useRef();
  const refCpanel = useRef();
  const refBeneficiaries = useRef();
  const refAdvantages = useRef();
  const refContactUs = useRef();

  const clickRefAbout = () => {
    refAbout.current.scrollIntoView({
      block: "center",
    });
  };

  const clickRefCpanel = () => {
    refCpanel.current.scrollIntoView({
      block: "center",
    });
  };

  const clickRefBeneficiaries = () => {
    refBeneficiaries.current.scrollIntoView({
      block: "center",
    });
  };

  const clickRefAdvantages = () => {
    refAdvantages.current.scrollIntoView({
      block: "center",
    });
  };

  const clickRefContactUs = () => {
    refContactUs.current.scrollIntoView({
      block: "center",
    });
  };

  return (
    <div
      className="position-relative"
      style={{
        minHeight: "100vh",
        marginTop: "100vh - 80px",
      }}
    >
      <nav
        ref={refHeader}
        className="nana navbar navbar-expand-md bg-white shadow-sm p-0 w-100 position-fixed"
        style={{ zIndex: "1000", transition: "var(--timeing)" }}
      >
        <HeaderHomePage
          refScroll={[
            clickRefAbout,
            clickRefCpanel,
            clickRefBeneficiaries,
            clickRefAdvantages,
            clickRefContactUs,
          ]}
        />
      </nav>
      <HLanding />
      <div ref={refAbout}>
        <About />
      </div>
      <div ref={refCpanel}>
        <SectionCpanel />
      </div>
      <div ref={refBeneficiaries}>
        <SectionBeneficiaries />
      </div>
      <div ref={refAdvantages}>
        <SectionAdvantages />
      </div>
      <div ref={refContactUs}>
        <SectionContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
