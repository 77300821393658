/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ButtonLink from "../../duplicateComponents/ButtonLink";

const JoinRequestCard = ({ logo, title, path, imgLoad }) => {
  return (
    <div className="col-6 col-sm-4 col-xl-3">
      <div className="card rounded-3 border-0 style-shadow-small text-center">
        {imgLoad ? null : logo === null ? (
          <div className=" d-flex justify-content-center p-2 card-img-top">
            <img
              src={"../../../images/img-join-request.png"}
              // className="card-img-top"
              style={{ width: "50px", height: "50px" }}
              id="img-join-request-width"
              alt="logo"
            />
          </div>
        ) : (
          <div className=" d-flex justify-content-center p-2 card-img-top">
            <img
              src={logo}
              // className="card-img-top"
              style={{ width: "50px", height: "50px" }}
              id="img-join-request-width"
              alt="logo"
            />
          </div>
        )}
        {imgLoad ? (
          <div className="d-flex justify-content-center p-3 placeholder-glow">
            <div
              className="placeholder"
              style={{
                width: "9rem",
                backgroundColor: "var(--primary-color-opacity)",
                height: "6rem",
              }}
              role="status"
            >
              <span className="visually-hidden">Loading Img...</span>
            </div>
          </div>
        ) : null}
        <div
          className="card-title title-company-name-fs px-4 py-1 fw-bold"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 14,
          }}
        >
          {title}
        </div>
        <div className="m-2">
          <ButtonLink
            width={"100%"}
            title={"عرض التفاصيل"}
            fs={"10px"}
            path={path}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinRequestCard;
