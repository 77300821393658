/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Taps from "../../../duplicateComponents/Taps";
import FollowUpGradeContainerLogic from "../../../../logic/student/grades/FollowUpGradeContainerLogic";
import FollowUpGradesSummaryContainer from "./taps/FollowUpGradesSummaryContainer";
import FollowUpGradesDetailsContainer from "./taps/FollowUpGradesDetailsContainer";
const FollowUpGradeContainer = () => {
  const [myDataGrades, loading] = FollowUpGradeContainerLogic();

  // console.log(myDataGrades && myDataGrades);
  return (
    <div className="">
      <Taps
        title={["ملخص الدرجات", "تفاصيل الدرجات النهائية"]}
        charIdAndTarget={["a", "b"]}
        titleItem={[
          <FollowUpGradesSummaryContainer
            propsMyDataCompnay={myDataGrades && myDataGrades}
            propsLoading={loading}
          />,
          <FollowUpGradesDetailsContainer
            propsMyDataCompnay={myDataGrades && myDataGrades}
            propsLoading={loading}
          />,
        ]}
      />
      {/* <div className="row ">
        <div className="col-12 col-lg-6 mt-4 m-auto">
          <FollowUpGradeTotalCard grade={23} percent={42} />
        </div>
      </div> */}
      {/* <TableContainer
        className={"my-5"}
        thead={["#", "البنود", "الدرجة", "نسبة الانجاز"]}
        item={
          <>
            <FollowUpGradeTableCard
              index={1}
              title={"تقييم جهة التدريب"}
              limitGrade={30}
              grade={null}
              percent={null}
            />
            <FollowUpGradeTableCard
              index={2}
              title={"تقييم مشرف التدريب"}
              limitGrade={40}
              grade={12}
              percent={49}
            />
            <FollowUpGradeTableCard
              index={3}
              title={"تقييم ومناقشة التقرير النهائي "}
              limitGrade={30}
              grade={35}
              percent={100}
            />
          </>
        }
      /> */}
      {/* <div className="row ">
        <FormInputTitleHead
          title={"تفاصيل الدرجات"}
          fs={"30px"}
          textAlign={"center"}
        />
        <div className="col-12 col-lg-6">
          <TimeLineBoxCardDarkGreen
            titleHeader={"تقييم المتدرب من جهة التدريب"}
            fs={"20px"}
            item={
              <div>
                <TimeLineWithTitleAndSubTitle
                  title={"تقبل التوجيهات والارشادات"}
                  subTitle={"7"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
              </div>
            }
          />
        </div>
        <div className="col-12 col-lg-6">
          <TimeLineBoxCardLightGreen
            titleHeader={"تقييم مشرف التدريب"}
            fs={"20px"}
            item={
              <div>
                <TimeLineWithTitleAndSubTitle
                  title={"تقبل التوجيهات والارشادات"}
                  subTitle={"7"}
                  whatColor={"darkGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"darkGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"darkGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"darkGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"darkGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"darkGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"darkGreen"}
                />
              </div>
            }
          />
        </div>
        <div className="col-12 col-lg-6">
          <TimeLineBoxCardDarkGreen
            titleHeader={"تقييم ومناقشة التقرير النهائي"}
            fs={"20px"}
            item={
              <div>
                <TimeLineWithTitleAndSubTitle
                  title={"تقبل التوجيهات والارشادات"}
                  subTitle={"7"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"المظهر الشخصي"}
                  subTitle={"5"}
                  whatColor={"lightGreen"}
                />{" "}
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
                <TimeLineWithTitleAndSubTitle
                  title={"العلاقة مع زملاء العمل"}
                  subTitle={"6"}
                  whatColor={"lightGreen"}
                />
              </div>
            }
          />
        </div>
      </div> */}
    </div>
  );
};

export default FollowUpGradeContainer;
