/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import Fade from "react-reveal/Fade";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudent } from "../../redux/actions/student/ActionStudent";
import { getAllCollage } from "../../redux/actions/collage/ActionCollage";
import { getAllSopervisor } from "../../redux/actions/sopervisor/ActionSopervisor";
import { getAllCompany } from "../../redux/actions/company/ActionCompany";
import ReactLoading from "react-loading";
import CountUp from "react-countup";
import { Statistic } from "antd";
const SectionAdvantages = () => {
  const myDispatch = useDispatch();
  useEffect(() => {
    myDispatch(getAllStudent());
    myDispatch(getAllCollage());
    myDispatch(getAllSopervisor());
    myDispatch(getAllCompany());
  }, []);

  const formatter = (value) => <CountUp end={value} separator="," />;
  // api student
  const myDataStudent = useSelector((state) => state.rootStudent.student);
  const loadingStudent = useSelector((state) => state.rootStudent.loading);

  // api collage
  const myDataCollage = useSelector((state) => state.rootCollage.collage);
  const laodingCollage = useSelector((state) => state.rootCollage.loading);

  // api company
  const myDataCompany = useSelector((state) => state.rootCompany.allCompany);
  const loadingCompany = useSelector(
    (state) => state.rootCompany.loadingAllCompany
  );
  // api sopervsior
  const myDataSopervisor = useSelector(
    (state) => state.rootSopervisor.sopervisor
  );
  const loadingSopervisor = useSelector(
    (state) => state.rootSopervisor.loading
  );

  // console.log(myDataCollage);
  const dataAdvantages = [
    {
      title: "المتدربين",
      countUp: loadingStudent ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        myDataStudent && myDataStudent.total
      ),
    },
    {
      title: "الكليات",
      countUp: laodingCollage ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        myDataCollage && myDataCollage.total
      ),
    },
    {
      title: "الجهات",
      countUp: loadingCompany ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        myDataCompany && myDataCompany.total
      ),
    },
    {
      title: "المشرفين",
      countUp: loadingSopervisor ? (
        <ReactLoading
          type={"bubbles"}
          color={"var(--primary-color)"}
          width={40}
          height={40}
        />
      ) : (
        myDataSopervisor && myDataSopervisor.total
      ),
    },
  ];

  return (
    <div
      style={{
        backgroundImage: "url('../../images/wavy-homepage-count-up.png')",
        backgroundRepeat: "no-repeat",

        minHeight: "60vh",
        backgroundSize: "100% 90%",
      }}
    >
      {" "}
      <div className="container  p-3 p-md-5 mt-5" id="advantages-homepage">
        <TitleHeadSpecialHomePage title={"المستفيدين"} />
        {/* col one */}
        <div className="row gy-4 mt-0 mt-md-5">
          {dataAdvantages.map((item, i) => (
            <div
              className="col-6 col-lg-3 style-translateY-advantages "
              key={i}
            >
              <Fade bottom>
                <div className="position-relative bg-dange d-flex justify-content-center">
                  <img
                    src="../../images/icon-goal-homepage-opacisty.png"
                    className="style-goal-homepage-opacisty"
                    alt="homepage-blob"
                  />
                  <div
                    className="position-absolute"
                    style={{
                      top: "16px",
                    }}
                  >
                    <div className="fw-bolder fs-3 text-dark text-opacity-75 d-flex">
                      <span>
                        <Statistic value={item.countUp} formatter={formatter} />
                      </span>
                      <span>+</span>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4 fw-bold text-title-icons-blobs">
                  {item.title}
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionAdvantages;
